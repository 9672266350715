<template>
	<div>
		<el-table stripe v-loading="loading" :data="dataList">
			<el-table-column
				align="center"
				label="机构编号"
				prop="agentNo"
				min-width="80"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				align="center"
				label="上游编号"
				prop="lsAgentId"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				align="center"
				label="机构名称"
				prop="userName"
				min-width="160"
				:show-overflow-tooltip="true"
			/>

			<el-table-column
				align="center"
				label="等级"
				prop="orgLevel"
				min-width="60"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				align="center"
				label="层级关系"
				prop=""
				min-width="80"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					<a @click="showDialog(scope.row)">查看</a>
				</template>
			</el-table-column>
			<el-table-column
				align="center"
				label="联系人手机号"
				prop="contactMobileNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>

			<el-table-column
				align="center"
				label="上级编号"
				prop="parentAgentNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				align="center"
				label="上级名称"
				prop="parentUserName"
				min-width="140"
				:show-overflow-tooltip="true"
			/>

			<el-table-column
				align="center"
				label="新增时间"
				prop="createTime"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column label="结算状态" prop="setterStatus" min-width="100" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.setterStatus==1?'success':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.setterStatus">{{scope.row.setterStatus | setterStatusFM}}</el-button>
                </template>
            </el-table-column>
			<el-table-column
				align="center"
				label="交易分润代付开关"
				prop=""
				min-width="140"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.profitSwitch | switchFM }}
				</template>
			</el-table-column>
			<el-table-column
				align="center"
				label="笔数分润代付开关"
				prop=""
				min-width="140"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.settleProfitSwitch | switchFM }}
				</template>
			</el-table-column>
			<el-table-column
				align="center"
				label="返现开关"
				prop=""
				min-width="80"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.cashbackSwitch | switchFM }}
				</template>
			</el-table-column>
			<el-table-column label="审核状态" prop="status" min-width="100" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.status==1?'danger':scope.row.status==4?'success':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.status">{{scope.row.status | userStatusFM}}</el-button>
                </template>
            </el-table-column>
			<el-table-column
				label="操作"
				class-name="small-padding fixed-width textLeft"
				width="270"
				fixed="right"
			>
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						@click="handleReset(scope.row)"
						v-permission="'AGENT_INFO_RESET_PASSWORD'">
						<i class="el-icon-lock"></i>
						重置密码
					</el-button>
					<el-button
						size="mini"
						type="text"
						@click="handleUpdateBenefit(scope.row)"
						v-permission="'AGENT_INFO_UPDATE'">
						<i class="el-icon-edit"></i>
						修改分润
					</el-button>
					<el-button
						size="mini"
						type="text"
						@click="handleUpdateCashBack(scope.row)"
						v-permission="'AGENT_INFO_UPDATE'">
						<i class="el-icon-edit"></i>
						修改返现
					</el-button>
					<el-button
						size="mini"
						type="text"
						@click="handleUpdate(scope.row)"
						v-permission="'AGENT_INFO_UPDATE'"
						style="margin-left: 0;">
						<i class="el-icon-edit"></i>
						修改资料
					</el-button>
					<el-button
						size="mini"
						type="text"
						v-if="scope.row.status == '4'"
						@click="handleReback(scope.row)"
						v-permission="'AGENT_INFO_REVIEW_REJECTION'">
						<i class="el-icon-s-fold"></i>
						审核退回
					</el-button>
					<el-button
						size="mini"
						type="text"
						@click="handleDetail(scope.row)">
						<i class="el-icon-edit-outline"></i>
						详情
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
		<el-dialog
			title="层级关系"
			:visible.sync="centerDialogVisible"
			width="50%"
			center
		>
			<template>
				<el-table stripe :data="levelList" style="width: 100%">
					<el-table-column prop="" label="身份">
						<template slot-scope="scope">
							{{ scope.row.userRole | userRoleFM }}
						</template>
					</el-table-column>
					<el-table-column prop="orgLevel" label="级别" />
					<el-table-column prop="userName" label="名称" />
					<el-table-column prop="agentNo" label="编号" />
				</el-table>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="centerDialogVisible = false"
					>确 定</el-button
				>
			</span>
		</el-dialog>
		<!-- 修改分润 -->
		<el-dialog :visible.sync="updateBenefitShow" width="1100px">
			<BenefitConfig
				@change="closeDialog"
				:parentAgentNo="parentAgentNo"
				:show="updateBenefitShow"
				:agentNo="agentNo"
				:agentName="agentName"
				:isUpdate="true"
			></BenefitConfig>
		</el-dialog>
		<el-dialog :visible.sync="updateCashBackShow" width="1100px">
			<CashBackConfig
				@change="closeDialog2"
				:parentAgentNo="parentAgentNo"
				:show="updateCashBackShow"
				:agentNo="agentNo"
				:agentName="agentName"
				:isUpdate="true"
			></CashBackConfig>
		</el-dialog>
	</div>
</template>

<script>
import { AgentApi } from "@/api";
import BenefitConfig from "@/components/agentUpdateBase/benefitConfig";
import CashBackConfig from "@/components/agentUpdateBase/cashBackConfig";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	components: {
		BenefitConfig,
		CashBackConfig
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [],
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			centerDialogVisible: false,
			levelList: [],
			updateBenefitShow: false,
			parentAgentNo: "",
			updateCashBackShow: false,
			orgLevel: "",
			agentNo: "",
			agentName: ""
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		async getList() {
			this.loading = true;
			let result = await AgentApi.getOrglist(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		handleStatusChange(row) {
			let text = row.status === "DISABLE" ? "禁用" : "启用";
			this.$confirm("是否确定" + text + "?", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(async () => {
					let result = await AgentApi.agentInfo.edit(row.agentNo, {
						state: row.state
					});
					if (result.success) {
						this.getList();
					}
				})
				.catch(() => this.getList());
		},
		async handleUpdate(row) {
			this.$router.push({
				name: "organizationUpdate",
				query: {
					agentNo: row.agentNo,
					contactMobileNo: row.contactMobileNo
				}
			});
		},
		handleReset(row) {
			this.$confirm(
				"是否确定重置业务用户（包含机构/代理商）编号为" +
					row.agentNo +
					"的密码?",
				"警告",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}
			).then(async () => {
				let result = await AgentApi.resetPassword(row.agentNo);
				if (result.success) {
					this.$alert(result.data, {
						confirmButtonText: "确定"
					});
				}
			});
		},
		async showDialog(row) {
			let result = await AgentApi.getAgentLevel(row.agentNo);
			if (result.success) {
				this.centerDialogVisible = true;
				this.levelList = result.data;
			}
		},
		handleDetail(row) {
			this.$router.push({
				name: "organizationDetail",
				query: {
					agentNo: row.agentNo
				}
			});
		},
		handleUpdateBenefit(row) {
			this.updateBenefitShow = true;
			this.parentAgentNo = row.parentAgentNo;
			this.agentName = row.userName;
			this.agentNo = row.agentNo;
			this.orgLevel = row.orgLevel;
		},
		closeDialog(e) {
			this.updateBenefitShow = false;
		},
		handleUpdateCashBack(row) {
			this.updateCashBackShow = true;
			this.parentAgentNo = row.parentAgentNo;
			this.agentName = row.userName;
			this.agentNo = row.agentNo;
			this.orgLevel = row.orgLevel;
		},
		closeDialog2(e) {
			this.updateCashBackShow = false;
		},
		handleReback(row) {
			AgentApi.agentReviewRejection(row.agentNo, row).then(result => {
				if (result.success) {
					this.Message.success(result.message);
					this.getList();
				} else {
					this.Message.error(result.message);
				}
			});
		}
	}
};
</script>

<style scoped>
.app-container .el-table ::v-deep td.textLeft{
    text-align: left;
}
</style>
